import React, {Component} from 'react';
import Header from "./Header.js";
import mock_data from "./mock_data.js";
import './App.css';


class Info extends Component {

    render() {
        return (
            <div className="info_section">
                <Header/>
                <div className="info_page">
            HEAD – Publishing est une structure éditoriale multi-support fondée en 2020 par Dimitri Broquard, Julie Enckell et Anthony Masure, et rattachée à la Haute école d'art et de design de Genève (HEAD – Genève, HES-SO). Elle trouve son origine dans l’appétence marquée de l’institution pour la diversité des formats éditoriaux et la matière textuelle, qui irrigue ses enseignements comme sa production culturelle : microédition, écriture créative, revue en ligne ou publications de recherche. Le caractère prospectif de HEAD – Publishing réside dans la volonté d’inscrire l’action éditoriale au cœur de l’école et de la rendre repérable et visible.
<br/>
            <br/>
            Le site Web HEAD – Publishing répertorie toutes les publications passées de la HEAD – Genève (imprimées ou non) et propose ses nouvelles parutions, notamment la collection d'essais critiques Manifestes (2021), bilingue français/anglais et disponible sous licence libre dans de nombreux formats (impression offset, print-on-demand, PDF, lecture à l'écran). Ce principe d’ouverture participe d’une volonté d’accessibilité des contenus au plus grand nombre et du constat que les réalités de lectures sont différentes pour chacun·e·x·s.
                    <a  href="mailto:julie.enckell-julliard@hesge.ch">Contact</a>
                </div>

                <div className="info_page_colophon">
                 <span><h2>Crédits</h2></span>
                  <span>Design graphique: <a target="_blank" href="">Dimitri Broquard</a></span>
                    <br/>
                    <br/>
                  <span>Design algorithmique: <a target="_blank" href="https://juangomez.co/en">Juan Gomez</a></span>
                    <br/>
                    <br/>
                 <span>Typographie: <a href="https://abcdinamo.com/typefaces/whyte">Whyte Variable</a> </span>
                </div>



            </div>
        )
    }
}


export default Info;
