import React, { Component } from 'react';
import Book3DMockupContainers from "./Book3DMockupContainers"
import mock_data from "./mock_data.js"
import './App.css';


class Interactive3DLibrary extends Component {

  constructor(props) {
     super(props);
     this.state = {
      isActive: false,
      isMiniRotate: true,
      selected3DEntries: null
     }
   }


  componentDidMount(){

    if(this.props.strapi_data_books){

      let selected3DEntries = this.props.strapi_data_books
      .filter((ele) => ele.is3dA && ele.mockup3D);

      this.setState({
        selected3DEntries
      })
    }

  }



  renderBook3DMockups = () => {

    if(!this.state.selected3DEntries){
      return null;
    }

    let projects = this.state.selected3DEntries;
    let parentCoordinates = {
      x: this.props.position.x,
      y: this.props.position.y
    }

    let boooksToComponents = projects
    .map((ele, index) => {
      return (
        <div className="library_book_container" index={index}>
            <Book3DMockupContainers
                index={index}
                bookData={ele}
                parentCoordinates={parentCoordinates}
              />
        </div>
      )
    })
    return boooksToComponents;
  }


  render() {

    if(!this.state.selected3DEntries){
      return (
        <div>
          loading
        </div>
      )
    }

    return (
      <section className="library_main_container">
        {this.renderBook3DMockups()}
      </section>
    )
  }
}



export default Interactive3DLibrary;
