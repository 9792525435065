import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import './index.css';
import App from './App.js';
import Info from "./Info.js";
import CatalogueContainer from "./CatalogueContainer.js";
import ProductPage from "./ProductPage.js"
import Longread from "./Longread.js"
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <script src="/longread/js/jquery-library-3.1.1.js" type="text/javascript"/>
    </Helmet>
    <Router>
      <Switch>
        <Route exact path='/' component={App} />
        <Route exact path='/catalogue' component={CatalogueContainer} />
        <Route exact path='/info' component={Info} />
        <Route exact path='/:id' component={ProductPage} />
        <Route exact path='/longread/:id' component={Longread} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
