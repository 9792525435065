import React, {Component} from 'react';
import ReactHtmlParser from 'react-html-parser';
import Header from "./Header.js"
import './App.css';


class ProductPageScans extends Component {


    constructor(props) {
       super(props);
       this.state = {
          imgDiv: null
     }
   }


  componentDidMount(){

    let {data, imgId} = this.props;
    if(data){

      let imgDiv = () => {
        return (
          <div>
            <div className="image-scan">
              {this.renderImgScanShadow(imgId)}
              <img
                id={imgId}
                src={`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}` + data.url}/>
            </div>
          </div>
        )
      }
      this.setState({
        imgDiv: imgDiv()
      })
    }
  };

  renderImgScanShadow = (imgId) => {

    return (
      <div
        className="shadow_container">
          <div />
      </div>
    )
  }

  render(){
    const {imgDiv} = this.state;
    if(!imgDiv){
      return null;
    }
    return (
      <div>
         {imgDiv}
      </div>
    )
  }
}


export default ProductPageScans;
