import React, {Component} from 'react';
import ReactCursorPosition from 'react-cursor-position';
import CatalogueCellLabel from "./CatalogueCellLabel";
import {Link} from 'react-router-dom';
import './App.css';


class CatalogueCells extends Component {

  constructor(props) {
      super(props);
      this.state = {
        isHovered: false
      };
    }


    renderLink = (bookData) => {

      let {name_collection, slug} = bookData;

      if(name_collection.includes(" ")){
          let nameCollectionFormatted = name_collection.replace(/ /g,"_");
          let link = `/${bookData.slug.toLowerCase()}`;
          return link;
      }else{
          let link = `/${bookData.slug.toLowerCase()}`;
          return link;
      }
    }


  renderCellContainer = (data, topLevelSectionKey, cellIndex, sectionData) => {

    let cellId = `catalogue_cell_${topLevelSectionKey}_${cellIndex}`;
    let collection = this.props.data.name_collection;


    if(data.showCoverTemplate){

      let link = this.renderLink(this.props.data);

      const thumbnailUrl = data.thumbnailCell?.img?.url;

      return (
        <Link
          onMouseEnter={() => this.onMouseEnter(true)}
          onMouseLeave={() => this.onMouseEnter(false)}
          style={{position: "relative"}}
          to={link}>
          <ReactCursorPosition>
            <CatalogueCellLabel
                data={data}
                {...this.state}
            />
              <div
                id={cellId}
                style={this.renderDynamicStyling(sectionData, data.showCoverTemplate, data)}
                className="catalogue_cell_container">
                <img
                  loading="lazy"
                  className={this.renderDynamicClassName()}
                  src={`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}` + thumbnailUrl}/>
              </div>
          </ReactCursorPosition>
          </Link>
      )
    }else{

      let thumbnailCell = data.thumbnailCell.textTemplateData;

      // Add externalUrl here;
      return (
        <a
          href={thumbnailCell.externalUrl}
          target="_blank">
        <div
          style={this.renderDynamicStyling(sectionData, data.showCoverTemplate, data)}
          className="catalogue_cell_container cell_text">
            <h1>{thumbnailCell.fieldOne}</h1>
            <h1 className="subtitle_text">{thumbnailCell.fieldTwo}</h1>
            <div className="cell_text_parser_div">
                <p>{thumbnailCell.fieldThree}</p>
            </div>
        </div>
        </a>
      )



      return null;
    }
  }

  renderDynamicClassName = () => {
    const { size_for_3d } = this.props.data

    let hoverSizeCls

    if (size_for_3d) {
      hoverSizeCls = size_for_3d.big ? 'big' : size_for_3d.small ? 'small' : 'medium'
    }

    if(this.state.isHovered){
      return `img_hover ${hoverSizeCls}`
    }else{
      return `${hoverSizeCls}`
    }
  }


  renderDynamicStyling = (sectionData, coverTemplateData, data) => {

    if(sectionData === undefined
      && coverTemplateData){
      return null;
    }

    if(coverTemplateData){
      return (
        {
          backgroundColor: sectionData.color
        }
      )
    }else{

      let strapiBookSectionsData = this.props.strapiBookSectionsData;
      let entryCollection = data.name_collection;


      let textTemplateColor = strapiBookSectionsData.filter((ele) => {
        if (ele.name === entryCollection) {
          return ele;
        }
      });

      return (
        textTemplateColor[0] ?
          {
            backgroundColor: textTemplateColor[0].color
          } : {}
      )
    }

  }


  onMouseEnter = (bool) => {
    this.setState({
      isHovered: bool
    })
  }


  render(){

    let data = this.props.data;
    let key= this.props.key;
    let collection = this.props.data.name_collection;
    let indexOfTopLevelSection = this.props.indexOfTopLevelSection;
    let topLevelSectionKey = this.props.topLevelSectionKey;
    let cellIndex = this.props.cellIndex;
    let sectionData = this.props.sectionData;


    if (!data) {
        return (
            <div>
                loading
            </div>
        )
    }

    return this.renderCellContainer(data, topLevelSectionKey, cellIndex, sectionData);

  }
}


export default CatalogueCells;
