import React, {Component} from 'react';
import * as THREE from 'three';

const baseLink = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}`;
const publicLink = `${process.env.REACT_APP_BACKEND_URL}`;

const BOOK_Z_SPACING_FACTOR = 1 / 30

let BOOKS_IN_ROW
let BOOK_Z_SPACING, BOOK_MAX_DEPTH = Number.NEGATIVE_INFINITY

const createBook = (bookData) => {
  const {mockup3D, slug} = bookData

  let coverImgUri = baseLink + mockup3D.coverImg.url;
  let spineImgUri = baseLink + mockup3D.spineImg.url;
  let sideImg = baseLink + mockup3D.sideImg.url;
  let backImgUri = baseLink + mockup3D.backImg.url;

  // Load the textures (book images)
  var textureLoader = new THREE.TextureLoader();
  var bookCoverTexture = textureLoader.load(coverImgUri);
  var bookSpineTexture = textureLoader.load(spineImgUri);
  var bookBackTexture = textureLoader.load(backImgUri);

  var bookPagesTexture = textureLoader.load('https://head-publishing.ch/livres/southern-gems-pages.png');
  var bookPagesTopBottomTexture = textureLoader.load( 'https://head-publishing.ch/livres/southern-gems-pages-topbottom.png');


  // Use the linear filter for the textures to avoid blurriness
  bookCoverTexture.minFilter
    = bookSpineTexture.minFilter
    = bookBackTexture.minFilter
    = bookPagesTexture.minFilter
    = bookPagesTopBottomTexture.minFilter
    = THREE.LinearFilter;


  // Create the materials

  const bookCover = new THREE.MeshLambertMaterial({color: 0xffffff, map: bookCoverTexture});
  const bookSpine = new THREE.MeshLambertMaterial({color: 0xffffff, map: bookSpineTexture});
  const bookBack = new THREE.MeshLambertMaterial({color: 0xffffff, map: bookBackTexture});
  const bookPages = new THREE.MeshLambertMaterial({color: 0xffffff, map: bookPagesTexture});
  const bookPagesTopBottom = new THREE.MeshLambertMaterial({color: 0xffffff, map: bookPagesTopBottomTexture});

  const materials = [
    bookPages,          // Right side
    bookSpine,          // Left side
    bookPagesTopBottom, // Top side
    bookPagesTopBottom, // Bottom side
    bookCover,          // Front side
    bookBack            // Back side
  ];

  let { height = 10, width = 1.2, depth = 7} = mockup3D

  // testing

  // height = mockup3D.height || Math.floor(10 - Math.random() * 2);
  // width = .5 + Math.random();
  // depth = 4 + Math.random() * 6;

  // eof testing

  if (depth > BOOK_MAX_DEPTH) {
    BOOK_MAX_DEPTH = depth
  }

  const geometry = new THREE.BoxBufferGeometry(depth, height, width, 4, 4, 1);
  geometry.translate( 0, 0.5 * height, 0 );

  const book = new THREE.Mesh(geometry, materials);

  book.callback = function () {
    window.location.href = `/${slug.toLowerCase()}`
  }

  return book
}

class ThreeJsBooks extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }


  componentDidMount() {
    const {props, containerEl} = this;
    const {booksData} = props

    const {width: containerWidth, height: containerHeight} = containerEl.getBoundingClientRect()

    const renderer = new THREE.WebGLRenderer({alpha: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(containerWidth, containerHeight);
    containerEl.appendChild(renderer.domElement);

    window.addEventListener( 'resize', onWindowResize, false );

    function onWindowResize(){
      const {width: containerWidth, height: containerHeight} = containerEl.getBoundingClientRect()

      camera.aspect = containerWidth / containerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(containerWidth, containerHeight);
    }

    const scene = new THREE.Scene();

    //scene.add( new THREE.AxesHelper( 50 ) );

    const ambientLight = new THREE.AmbientLight(0xffffff);
    scene.add(ambientLight);

    let bookIdx = 1, books = []
    const bookData3d = booksData.filter(bookData => bookData.is3dA && bookData.mockup3D)

    BOOKS_IN_ROW = bookData3d.length
    BOOK_Z_SPACING = (containerWidth / BOOKS_IN_ROW) * BOOK_Z_SPACING_FACTOR

    if (bookData3d.length) {
      for (const bookData of bookData3d) {
        const book = createBook(bookData)
        const z = ((bookIdx - 1) % BOOKS_IN_ROW) * BOOK_Z_SPACING;
        book.position.set(0, 0, z);
        scene.add(book);
        books.push(book)
        bookIdx++
      }
    }

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    document.addEventListener('mousemove', event => {
      const { top, left } = containerEl.getBoundingClientRect()

      mouse.x = ((event.clientX - left) / renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -((event.clientY - top) / renderer.domElement.clientHeight) * 2 + 1;
      document.body.style.cursor="default";
    })

    document.addEventListener('mousedown', onDocumentMouseDown)

    function onDocumentMouseDown(event) {
      event.preventDefault();

      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(books);

      if (intersects.length > 0) {
        intersects[0].object.callback();
        document.body.style.cursor="pointer";
      }
    }

    const camera = new THREE.PerspectiveCamera(
      22,                                     // Field of view
      containerWidth / containerHeight,       // Aspect ratio
      0.1,                                    // Near plane distance
      1000                                    // Far plane distance
    );

    // Position the camera
    const pivot = new THREE.Object3D()
    const zCenter = ((BOOKS_IN_ROW - 1) / 2) * BOOK_Z_SPACING;

    pivot.position.set(0, 5, zCenter);
    scene.add(pivot)
    camera.position.x = -50 // zoom put
    pivot.add(camera)
    camera.lookAt(pivot.position)

    // manual controls - NB enabling this will prevent mouse on books
    // const controls = new OrbitControls(camera, containerEl);
    // controls.target.set(0, -1, zCenter);
    // controls.update();
    containerEl.addEventListener('mousemove', function (e) {
      let scale = -0.0014;
      pivot.rotateY(e.movementX * scale);
    })


    animate();

    function animate() {
      const debugRayMarching = () => {
        for (const child of books) {
          for (const material of child.material) {
            material.color.set( 0xffffff )
          }
        }

        // update the picking ray with the camera and mouse position
        raycaster.setFromCamera( mouse, camera );
        // calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObjects( books );

        for ( let i = 0; i < intersects.length; i ++ ) {
          for (const materialElement of intersects[i].object.material) {
            materialElement.color.set( 0xCF303D )
            document.body.style.cursor="pointer";
          }
        }
      }

      debugRayMarching()

      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    }
  }

  render() {
    return (
      <div className={'threeJsBooksContainer'} ref={ref => (this.containerEl = ref)} />
    )
  }
}

export default ThreeJsBooks;
