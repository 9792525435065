import React, { Component } from 'react';
import ReactCursorPosition from 'react-cursor-position';
import Book3DMockup from "./Book3DMockup";
import mock_data from "./mock_data.js"
import './App.css';


class Book3DMockupContainers extends Component {

  render() {

      if(!this.props){
        return (
          <div>
              loading
          </div>
        )
      }

      let style = {
        height: "100%",
        width: "100%"
      };

      return(
        <ReactCursorPosition style={style}>
          <Book3DMockup
            index={this.props.index}
            bookData={this.props.bookData}
            parentCoordinates={this.props.parentCoordinates}
          />
        </ReactCursorPosition>
      )
  }
}

export default Book3DMockupContainers;
