import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import mock_data from "./mock_data.js"
import './App.css';


class Book3DMockup extends Component {

  constructor(props) {
     super(props);
     this.state = {
      isActive: false,
      isMiniRotate: true
     }
   }


  componentDidMount(){

    let position = this.props.position;
    let isActive = this.props.isActive;
    let isPositionOutside = this.props.isPositionOutside;
    let id = `3D-book-container${this.props.index}`;

    // this;
    let targeted3DContainer = document.getElementById(id);
    targeted3DContainer.addEventListener('transitionend', (event) =>  {
      this.setState({
        isMiniRotate: true
      })
    }, false );

    this.setState({
      isActive: isActive
    })
  }

  componentDidUpdate(prevProps, prevState) {

    let parentCoordinates = this.props.parentCoordinates;
    let id = `3D-book-container${this.props.index}`;
    let targeted3DContainer = document.getElementById(id);

    if(!this.state.isActive){

      targeted3DContainer.style.transform =
      `rotateY(${parentCoordinates.x/8}deg)
      rotateX(-${parentCoordinates.y/10}deg)`;

    }

    // if (prevProps.isActive !== this.props.isActive) {
    //   this.setState({
    //     isActive: this.props.isActive,
    //     isMiniRotate: true
    //   })
    // }
  }

  renderLink = (bookData) => {

    let {name_collection, slug} = bookData;

    if(name_collection.includes(" ")){
        let nameCollectionFormatted = name_collection.replace(/ /g,"_");
        let link = `/${bookData.slug.toLowerCase()}`;
        return link;
    }else{
        let link = `/${bookData.slug.toLowerCase()}`;
        return link;
    }
  }


  render3DMockup = () => {

    let position = this.props.position;
    let bookData = this.props.bookData;
    let mockup3D = bookData.mockup3D;


    let baseLink = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}`;

    let coverImg = baseLink + mockup3D.coverImg.url;
    let spineImg = baseLink + mockup3D.spineImg.url;
    let sideImg = baseLink + mockup3D.sideImg.url;
    let backImg = baseLink + mockup3D.backImg.url;
    let topBottomImg = baseLink + "/uploads/book_top_c06582de0e.jpg";

    let dimensions = {
      height: mockup3D.backImg.height,
      width: mockup3D.backImg.width,
      depth: mockup3D.spineImg.width
    }

    let h = `${dimensions.height}px`;
    let w = `${dimensions.width}px`;
    let d = `${dimensions.depth}px`;

    let hh = `${dimensions.height/2}px`;
    let hw = `${dimensions.width/2}px`;
    let hd = `${dimensions.depth/2}px`;

    let link = this.renderLink(bookData)


    let styleBookFront = {
      backgroundImage: `url(${coverImg})`,
      width: w,
      height: h,
      backgroundPosition: "bottom right",
      transform: `translate3d(-${hw}, -${hh}, ${hd}) translate3d(0, 0, 0) rotateY(0deg)`,
    };


    let styleBookSideLeft = {
      backgroundImage: `url(${spineImg})`,
      width: d,
      height: h,
      transform: `translate3d(-${hw}, -${hh}, ${hd}) translate3d(-${hd}, 0, -${hd}) rotateY(-90deg)`
    };

    // fix for now;
    let {threed_right_value_1,threed_right_value_2} = mockup3D;

    let styleBookSideRight = {
      backgroundImage: `url(${sideImg})`,
      width: d,
      height: h,
      transform: `translate3d(${threed_right_value_1},${threed_right_value_2}, 35px) translate3d(371px, 0px, -35px) rotateY(90deg)`,
    };



    let styleBookSideTop = {
      backgroundImage: `url(${topBottomImg})`,
      width: w,
      height: d,
      transform: `translate3d(-${hw}, -${hh}, ${hd}) translate3d(0px, -${hd},-${hd}) rotateX(90deg)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition:" center center"
    };

    let {threed_bottom_value} = mockup3D;

    let styleBookSideBottom = {
      backgroundImage: `url(${topBottomImg})`,
      width: w,
      height: d,
      transform: `translate3d(-${hw}, ${threed_bottom_value}, ${hd}) translate3d(0px, ${w},-${hd}) rotateX(-90deg)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition:" center center"
    };

    let styleBookSideBack = {
      backgroundImage: `url(${backImg})`,
      width: w,
      height: h,
      transform: `translate3d(-${hw}, -${hh}, ${hd}) translate3d(0, 0, -${d}) rotateY(180deg)`
    };


    let id = `3D-book-container${this.props.index}`;

    return (
          <div className="boockup">
            <Link to={link}>
                <div
                  id={id}
                  className="book-container">
                  <div className="book-front" style={styleBookFront}></div>
                  <div className="book-side-left" style={styleBookSideLeft}></div>
                  <div className="book-side-right" style={styleBookSideRight}></div>
                  <div className="book-top" style={styleBookSideTop}></div>
                  <div className="book-bottom" style={styleBookSideBottom}></div>
                  <div className="book-back" style={styleBookSideBack}></div>
                </div>
              </Link>
          </div>
    )
  };



  // removed from now;
  dynamicStyleBookContainer = () => {

    let position = this.props.position;
    let state = this.state;

    if(this.state.isActive){
      return (
        { transform: `rotateY(${position.x}deg)`}
      )
    }else{
      return (
        {
          transform: `rotateY(0deg)`,
          transition: "3s"
        }
      )
    }
  };


  render() {

      if(!this.props.position){
        return (
          <div>
              loading
          </div>
        )
      }

      return(
        <div className="container">
          {this.render3DMockup()}
        </div>
      )
  }
}



export default Book3DMockup;
