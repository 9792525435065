import React, {Component} from 'react';
import ProductPageScans from "./ProductPageScans";
import ReactHtmlParser from 'react-html-parser';
import Header from "./Header.js"
import ReactMarkdown from 'react-markdown'
import './App.css';


class ProductPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entryColor: null,
            entryData: null,
            strapiBookSectionsData: null,
            isOnlineScan: false,
            isLongRead: false,
            params: null,
            id: null,
            entryLongReadData: null,
            size_for_3d: null,
        }
    };

    async componentDidMount() {

        let props = this.props;
        let params = props.match.params.type;
        let slug = props.match.params.id;

        const respGetBooks = await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/books?_limit=-1`, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        const allBooksData = await respGetBooks.json()

        const entryData = allBooksData.find(e => e.slug === slug);

        if (entryData.HTML && entryData.HTML.url) {
            let entryLongReadURL = entryData.HTML.url;

            const respGetBookHtml = await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}${entryLongReadURL}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const entryLongReadData = await respGetBookHtml.text()

            this.setState({
                isloaded: true,
                entryLongReadData
            })
        }

        const respBookSection = await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/book-sections-data?_limit=-1`, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        let strapiBookSectionsData = (await respBookSection.json()).bookSections2

        let entrySlug = entryData.name_collection;
        let entrySectionData = strapiBookSectionsData.find(e => e.name === entrySlug) || {};

        this.setState({
            isloaded: true,
            strapiDataBooks: allBooksData,
            entryData,
            entryColor: entrySectionData.color,
            strapiBookSectionsData
        })
    }


    renderHeader = () => {
        return (
            <Header
                {...this.state}
                productPage={true}
            />
        )

    }

    renderLeftSection = () => {

        return (
            <div
                style={{backgroundColor: this.state.entryColor}}
                className="product_page_left_section">
                {this.renderLeftSectionBody()}
            </div>
        )
    }


    renderRightSection = () => {
        return (
            <div className="product_page_right_section">
                {this.renderRightSectionBody()}
            </div>
        )
    }

    renderLeftSectionBody = () => {
        const input = '# This is a header\n\nAnd this is a paragraph'
        let entryData = this.state.entryData;

        return (
            <div className="product_page">

                <h2 className="italic">{entryData.title}</h2>
                <h2>{entryData.author}</h2>

                <div className="product_page_left_section_dimensions">
                    {this.renderEditorText(entryData)}
                    <p
                        style={{display: this.renderDynamicStyling(entryData)}}
                        className="collection">
                        Collection : {entryData.name_collection}
                    </p>
                    <p>{entryData.Dimensions}</p>
                    <p>{entryData.year}</p>
                </div>

                <div className="product_page_left_section_description">
                    <ReactMarkdown source={entryData.rich_text} />
                </div>
            </div>
        )
    }

    renderDynamicStyling = (entryData) => {
      let collection = entryData.name_collection;
      if(collection === "Autres collections"){
        return "none"
      }else{
        return "block"
      }
    };

    renderEditorText = (entryData) => {
      let editor = entryData.editor;

      if(editor.includes(", ")){
        return (
          <p>Éditeurs : {entryData.editor}</p>
        )
      }else{
        return (
          <p>Éditeur : {entryData.editor}</p>
        )
      }
    };


    renderRightSectionBody = () => {

        let entryData = this.state.entryData;
        const {externalLinks, thumbnailCell} = entryData;

        return (
          <div>
              {thumbnailCell && this.renderBookCover(thumbnailCell.img.url)}
              {this.renderProductPageMenu(externalLinks, entryData)}
          </div>
        )
    };


    renderBookCover = (cover) => {
        return (
            <div className="product_page_book_container">
                <img src={`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}` + cover}/>
            </div>
        )
    };

    renderProductPageMenu = (menuContent, entryData) => {
        const {preview_images, LULU_link, alternative_Epub_Source, main_epub_source, Acheter_le_livre, alternative_PDF_source, main_pdf_source, html_link, slug} = entryData;
        const pdf_source = main_pdf_source || alternative_PDF_source;
        const epub_source = main_epub_source || alternative_Epub_Source;

        return (
          <div className="product_page_menu_section">
              {this.renderScansSectionCTADiv(preview_images)}
              {Acheter_le_livre && <div>
                  <h1>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={Acheter_le_livre}>
                          Livre imprimé
                      </a>
                  </h1>
              </div>}
              {LULU_link && <div>
                  <h1>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={LULU_link}>
                          Impression à la demande
                      </a>
                  </h1>
              </div>}
              {html_link && <div>
                  <h1>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"longread/" + slug}>
                          Lire à l’écran
                      </a>
                  </h1>
              </div>}
              {epub_source && <div>
                  <h1 className="epub_hover">
                      <a target="_blank"
                         download
                         href={epub_source}>
                          ePub
                      </a>
                  </h1>
                  <img className="hide"
                       src="https://res.cloudinary.com/juangomez/image/upload/v1615371589/Plan_de_travail_1_2x_c624f6a314_v5upfn.png"
                       width="100px" alt=""/>

              </div>}
              {pdf_source && <div>
                  <h1>
                      <a
                        rel="noopener noreferrer"
                        href={pdf_source}>
                          PDF
                      </a>
                  </h1>
              </div>}
            </div>
        )
    }


    renderScansSectionCTADiv = (previewImages) => {

        if (previewImages.length === 0) {
            return null;
        }
        return (
            <div>
                <h1>

                    <a onClick={this.toggleScansSection}
                       className="product_page_cta_scans">
                        Aperçu du livre imprimé</a>

                </h1>
            </div>
        )
    }


    toggleScansSection = () => {
        this.setState({
            isOnlineScan: !this.state.isOnlineScan
        })
    };

    renderScansSection = (preview_images) => {

        if (!this.state.isOnlineScan) {
            return null;
        }

        let previewImagesToDiv = preview_images.map((ele, index) => {
          let imgId = `product_page_scan_${index}`
            return (
                <ProductPageScans
                  imgId={imgId}
                  key={index}
                  data={ele}
                  {...this.state}
                />
            )
        })

        return (
            <div
                onClick={this.toggleScansSection}
                className="scan_section_container">
                {previewImagesToDiv}
                <div className="right">
                </div>
                <div className="left">
                </div>
            </div>
        )
    }

    renderScansSectionCTA = () => {

        if (!this.state.isOnlineScan) {
            return null;
        }

        return (
            <div
                onClick={this.toggleScansSection}
                className="scan_section_cta">
            </div>
        )
    }


    triggerLongRead = () => {
        this.setState({
            isLongRead: !this.state.isLongRead
        })
    }


    renderLongRead = () => {


        if (!this.state.isLongRead
            || !this.state.entryLongReadData) {
            return null;
        }

        let long_read_style = {
            position: "fixed",
            height: "100vh",
            width: "100vw",
            top: "0",
            left: "0",
            zIndex: 1,
            backgroundColor: "white",
            overflowY: 'scroll'
        }

        return (
            <div className='longread_container' style={long_read_style}>
                {ReactHtmlParser(this.state.entryLongReadData)}
            </div>
        )
    }


    render() {

        let state = this.state;
        let entryData = state.entryData

        if (!state.entryData) {
            return (
              <main>
                  loading
              </main>
            )
        }

        return (
          <div>
              {this.renderHeader()}
              {this.renderLongRead()}
              <div className='product_page_container'>
              {this.renderLeftSection()}
              {this.renderRightSection()}
              </div>
              {this.renderScansSection(entryData.preview_images)}
              {this.renderScansSectionCTA()}
          </div>
        )
    }
}


export default ProductPage;
