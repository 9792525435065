import React, {Component} from 'react';
import Header from "./Header.js";
import MarqueeContainer from "./MarqueeContainer.js";
import CatalogueContainer from "./CatalogueContainer.js";
import './App.css';
import ThreeJsBooks from "./ThreeJsBooks";
import Interactive3DLibrary from "./Interactive3DLibrary";
import ReactCursorPosition from "react-cursor-position";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            strapi_data_books: null,
            strapi_data_news: null,
            book_sections_data: null,
            header: null,
            error: null,
            isLoaded: false,
        }
    }

    componentDidMount() {

        // fetching the book entries data from the cms;
      fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/books`,{
          headers: {
              "Content-Type": "application/json"
          }
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.setState({
                strapi_data_books: result
            });
        },
        (error) =>{
            this.setState({
                isLoaded: true,
                error
            });
        });

        // fetching the news-pages (marquee) data from the cms;
        fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/news-pages`,{
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    strapi_data_news: result
                });
            },
            (error) =>{
                this.setState({
                    isLoaded: false,
                    error
                });
            });

        // fetching the book-sections-data from the cms;
        fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/book-sections-data`,{
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isloaded: true,
                    book_sections_data: result
                });
            },
            (error) =>{
                this.setState({
                    isLoaded: false,
                    error
                });
            });
      }


    render() {
      const {strapi_data_books} = this.state;

      if (!strapi_data_books) {
            return (
                <main>
                    loading
                </main>
            )
        }

        return (
            <main>
                <Header {...this.state}/>
                <MarqueeContainer {...this.state}/>
                <ThreeJsBooks booksData={strapi_data_books}/>
                <ReactCursorPosition>
                  <Interactive3DLibrary {...this.state} />
                </ReactCursorPosition>
                <CatalogueContainer {...this.state} />
            </main>
        )
    }
}

let divs = document.getElementsByClassName('collection');

for (let x = 0; x < divs.length; x++) {
    let div = divs[x];
    let content = div.innerHTML.trim();

    if (content == 'Autres collections') {
        div.style.display = 'none';
    }
}


export default App;
