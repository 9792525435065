import React, {Component} from 'react';
import './App.css';


class CatalogueCellLabel extends Component {

  render(){

    let data = this.props.data;
    if(!this.props.isActive || !data){
      return null;
    }

    const {author, Dimensions, year, title, editor} = data;

    let dynamicStyling = {
      position: "absolute",
      left: this.props.position.x,
      top: this.props.position.y
    };

    return(
      <div
        style={dynamicStyling}
        className="hovered_catalogue_image_data">
        <div>
          <span>{this.props.isHovered}</span>
          <span>{title}</span>
          <span>{author}</span>
        </div>
        <div>
          <span>{editor}</span>
          <span>{Dimensions}</span>
          <span>{year}</span>
        </div>
    </div>
    )
  }
}

export default CatalogueCellLabel;
