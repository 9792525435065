import React, {Component} from 'react';
import mock_data from "./mock_data.js"
import './App.css';


class MarqueeContainer extends Component {

    renderMarquees = () => {

        let marquees = this.props.strapi_data_news;
        let marqueesToComponents = marquees
            .map((marquee, index) => {

               let marqueeId = `marquee_${index}`

                return (
                    <a
                      onMouseEnter={() => this.handleMarqueeHover(true, marqueeId, marquee)}
                       onMouseLeave={() => this.handleMarqueeHover(false, marqueeId, marquee)}
                       key={index}
                       href={marquee.link}
                       id={marqueeId}
                       className="marquee_container"
                       target="_blank"
                       >
                        <div
                          style={{animationDuration: `${marquee.speed}s`}}
                          className="marquee__inner"
                          aria-hidden="true"
                          >
                            <span>{marquee.description}</span>
                            <span>{marquee.description}</span>
                            <span>{marquee.description}</span>
                            <span>{marquee.description}</span>
                        </div>
                    </a>
                )
            })
        return marqueesToComponents;
    }


    handleMarqueeHover = (hovered, marqueeId, marqueeData) => {

      let selectedMarquee = document.getElementById(marqueeId);
      let marqueeBackground = marqueeData.backgroundImage;

      if(hovered){
        selectedMarquee.style.backgroundImage = `url(${marqueeBackground})`;
        selectedMarquee.style.backgroundSize = "100%";
        selectedMarquee.style.backgroundRepeat = "no-repeat";
      }else{
        selectedMarquee.style.backgroundImage = null;
      }

    }

    render() {
        if (!this.props.strapi_data_news) {
            return (
                <div>
                    loading
                </div>
            )
        }
        return (
            <section className="marquee_main_container">
                {this.renderMarquees()}
                <p>{}</p>
            </section>
        )
    }
}


export default MarqueeContainer;
