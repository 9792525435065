import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import mock_data from "./mock_data.js"

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            header_data: null,
            isMenuOpen: false,
            scrollY: 0
        }
    }

    componentDidMount() {

        let headerData = this.props.header;
        fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/header`,{
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isloaded: true,
                    header_data: result
                });
            },
            (error) =>{
                this.setState({
                    isLoaded: false,
                    error
                });
            });


        let productPage = this.props.productPage;
        if (!productPage) {
            document.addEventListener("scroll", this.scrollHandler);
        } else {
            return null;
        }
    }

    scrollHandler = (event) => {
        let scrollY = window.scrollY;
        this.setState({
            scrollY
        })
    }

    toggleMenu = () => {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        }, () => {

            let headerMenuImg = document.getElementsByClassName("header_menu_burger_img");
            let headerMenuImgToDiv = [...headerMenuImg];

            if (this.state.isMenuOpen) {

                headerMenuImgToDiv[0].style.transform = "rotate(-20deg)";
                headerMenuImgToDiv[0].style.transition = "0.3s"

                headerMenuImgToDiv[1].style.transform = "rotate(-6deg)";
                headerMenuImgToDiv[1].style.transition = "0.3s"

                headerMenuImgToDiv[2].style.transform = "rotate(6deg)";
                headerMenuImgToDiv[2].style.transition = "0.3s"

                headerMenuImgToDiv[3].style.transform = "rotate(20deg)";
                headerMenuImgToDiv[3].style.transition = "0.3s"


            } else {

                headerMenuImgToDiv[0].style.transform = "rotate(0deg)";
                headerMenuImgToDiv[0].style.transition = "0.3s"
                headerMenuImgToDiv[1].style.transform = "rotate(0deg)";
                headerMenuImgToDiv[1].style.transition = "0.3s"

                headerMenuImgToDiv[2].style.transform = "rotate(0deg)";
                headerMenuImgToDiv[2].style.transition = "0.3s"

                headerMenuImgToDiv[3].style.transform = "rotate(0deg)";
                headerMenuImgToDiv[3].style.transition = "0.3s"


            }
        })
    };


    renderHeaderChange = (headerImg) => {

        let scrollY = this.state.scrollY;
        let productPage = this.props.productPage;

        if (scrollY < 300 && !productPage) {
            return (
                <Link to="/">
                <div
                    id="header_logo_img"
                    className="header_logo_img">
                    <img
                        className="header_logo_img_large_version"
                        src={headerImg}/>
                </div>
                </Link>
            )
        } else {
            return (
                <Link to="/">
                <div
                    id="header_logo_img"
                    className="header_logo_img">
                    <img
                        className="header_logo_img_small_version"
                        src={headerImg}
                    />
                </div>
                </Link>
            )
        }
    }


    renderHeader = (headerImg) => {

        return (
            <header className="header_container">
              <div>
                {this.renderHeaderChange(headerImg)}
              </div>
            </header>
        )
    };


    renderHeaderMenu = (headerSections) => {

        if (!this.state.isMenuOpen) {
            return null
        }

        let dataSectionsToLinks = headerSections.map((ele, index) => {
          if(ele.url.includes("http")){
            return (
              <h1>
                <a
                    href={ele.url}
                    target="_blank">
                    {ele.text}
                </a>
              </h1>
            )
          }else{
            return (
              <h1>
                <Link to={ele.url}>
                    {ele.text}
                </Link>
              </h1>
            )
          }
        })

        return (
            <div className="header_body">
              {dataSectionsToLinks}
            </div>

        )
    }

    renderHeaderCTA = () => {
      return (
        <div
            onClick={this.toggleMenu}
            className="header_menu">
            <div className="header_menu_burger">
                <div className="header_menu_burger_img"/>
                <div className="header_menu_burger_img"/>
                <div className="header_menu_burger_img"/>
                <div className="header_menu_burger_img"/>
            </div>
        </div>
      )
    }


    render() {

        if (!this.state.header_data) {
            return "loading"
        }

        let headerSections = this.state.header_data.headerSections;
        let headerImg = this.state.header_data.headerImg;


        return (
            <div className="header_main_container">
                {this.renderHeader(headerImg)}
                {this.renderHeaderCTA()}
                {this.renderHeaderMenu(headerSections)}
            </div>
        )
    }
}


export default Header;
