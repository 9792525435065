import React, {Component} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from "react-helmet";
import './longread/index.css';


class Longread extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entryData: null,
            entryLongReadData: null,
        }
    };

    async componentDidMount() {
        let props = this.props;
        let slug = props.match.params.id;

        const respGetBooks = await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/books?_limit=-1`, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        const allBooksData = await respGetBooks.json()

        const entryData = allBooksData.find(e => e.slug === slug);
        const { html_link } = entryData

        if (html_link) {
            const respGetBookHtml = await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/${html_link}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const entryLongReadData = await respGetBookHtml.text()

            this.setState({
                entryData,
                entryLongReadData
            })
        } else {
            alert('todo : handle no longread')
        }
    }


    renderLongRead = () => {

        if (!this.state.isLongRead
            || !this.state.entryLongReadData) {
            return null;
        }

        let long_read_style = {
            position: "fixed",
            height: "100vh",
            width: "100vw",
            top: "0",
            left: "0",
            zIndex: 1,
            backgroundColor: "white",
            overflowY: 'scroll'
        }

        return (
            <div className='longread_container' style={long_read_style}>
                {ReactHtmlParser(this.state.entryLongReadData)}
            </div>
        )
    }


    render() {
        const {entryData, entryLongReadData} = this.state

        if (!entryData) {
            return (
              <main>
                  loading
              </main>
            )
        }

        const {longread_custom_script} = entryData

        const customScript = `
        function custom() {
            const done = () => {
                const root = document.getElementById('root');
                
                root.dataset.afterCustom = true;
                const event = new Event('afterCustom');
                document.getElementById('root').dispatchEvent(event);
            }
                
            ${longread_custom_script || 'done()'}
        }
        
         if (document.readyState === 'complete') {
            console.log('is complete')
            custom()          
         } else {
            window.addEventListener("load", function() {
            console.log('is loaded')
            custom()
         })
         }
        `

        let reactHtmlParser = ReactHtmlParser(entryLongReadData);
        let sections

        try {
            sections = reactHtmlParser[1].props.children[1].props.children
        } catch (e) {
            console.error(`cant parse html content`)
            return `cant parse html content`
        }

        return (
          <React.Fragment>
              <Helmet>
            
                  <script type='text/javascript'>
                      {customScript}
                  </script>
                  <script src="/longread/js/Main.js" type="text/javascript"/>
              </Helmet>

              <div className="progress-container-left">
                  <div className="progress-bar" id="myBarRight"></div>
              </div>
              <div className="progress-container-right">
                  <div className="progress-bar" id="myBarLeft"></div>
              </div>
              <header className="header_container">
                  <div><a href="https://head-publishing.ch/">
                      <div id="header_logo_img" className="header_logo_img">
                          <img
                              className="header_logo_img_small_version"
                              alt=""
                              src="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1611066855/HEAD_PUBLISHING/Logo-HP_1.svg">
                          </img>
                      </div>
                  </a></div>
              </header>
              <div className="wrapper">
                  <div className="content-article" id="content">
                      { sections }
                      <section className="footnotes">
                          <ol className="footnotes-list">
                          </ol>
                      </section>
                  </div>
                  <div className="r-notes-container notes-images">
                  </div>
                  <div className="images-container"></div>
              </div>
          </React.Fragment>
        )
    }
}


export default Longread;
