// - I've changed "cover" for coverTemplateData for more clarity
// - I've added the textTemplateData. fieldThree needs to be WYSIWYG
// - Info page data is needed
// - Since no more footer, I remove the footer {} here;
// - Header sections needs to be defined, e.g: Issue Journal?

// -  titre, collection, taille, annéelse


const mock_data = module.exports = {
    header: {
        headline: "—HEAD Publishing",
        headerImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1611066855/HEAD_PUBLISHING/Logo-HP_1.svg",
        headerSections: [

            {
                text: "Catalogue",
                url: "/catalogue"
            },
            {
                text: "ISSUE Journal",
                url: "https://issue-journal.ch/"
            },
            {
                text: "Info",
                url: "/info"
            },
            {
                text: "Search",
                url: "/search"
            }
        ],
    },
    infoPage: {},
    marquees: {
        entries: [
            {
                text: "1 - Lorem Ipsum is simply dummy text of the printing and collection",
                link: "marquee link 1",
                backgroundImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1611069956/HEAD_PUBLISHING/Hover-Marquee.svg",
                direction: "right",
                speed: "5"
            },
            {
                text: "2 - Lorem Ipsum is simply dummy text of the printing and collectionsetting",
                link: "marquee link 2",
                backgroundImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1611069956/HEAD_PUBLISHING/Hover-Marquee.svg",
                direction: "left",
                speed: "10"
            },
            {
                text: "3 - Lorem Ipsum is simply dummy text of the printing and collectionsetting",
                link: "marquee link 3",
                backgroundImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1611069956/HEAD_PUBLISHING/Hover-Marquee.svg",
                direction: "right",
                speed: "2"
            }
        ]
    },
    bookSectionsData: {
        categories: [
            {
                fr_text: "Par année",
                en_text: "By years",
                key: "year", // this cannot be changed from the cms

            },
            {
                fr_text: "Par collection",
                en_text: "By collections",
                key: "name_collection"
            },
            {
                fr_text: "Par coéditions",
                en_text: "By coéditions",
                key: "coEdition"
            }
        ],
        entries: [
            {
                name: "manifeste",
                title: "Coll. manifeste",
                color: "blue",
                desc: "Un manifeste est une déclaration personne ou un groupe expose un programme d’action ou une position. La collection Manifeste de la nouvelle structure HEAD–Publishing met en valeur des partis pris, réflexions et actions développés par des acteurs de l’art et du design pour faire face aux enjeux contemporains."
            },
            {
                name: "issue",
                title: "Journal. Issue",
                color: "#00ffbf",
                desc: "Issue est une déclaration personne ou un groupe expose un programme d’action ou une position. La collection Manifeste de la nouvelle structure HEAD–Publishing met en valeur des partis pris, réflexions et actions développés par des acteurs de l’art et du design pour faire face aux enjeux contemporains."
            },
            {
                name: "co-édition 1",
                title: "Co-édition 1",
                color: "#ff8000",
                desc: "Co-édition 1 est une déclaration personne ou un groupe expose un programme d’action ou une position. La collection Manifeste de la nouvelle structure HEAD–Publishing met en valeur des partis pris, réflexions et actions développés par des acteurs de l’art et du design pour faire face aux enjeux contemporains."
            }
        ]
    },
    projects: {
        entries: [
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
            {
                showCoverTemplate: true,
                showTextTemplate: false,
                featured3D: true,
                collection: "manifeste",
                coEdition: "co-édition 1",
                bookDimension: {
                    big: true,
                    medium: false,
                    small: false
                },
                slug: "interior-architecture-2",
                title: "Interior Architecture : Thinking in the Expanded Media",
                author: "Javier F. Contreras",
                editor: "Éditeur(s) : HEAD–Publishing",
                dimensions: "17 x 10,5 cm, 80 pages",
                descriptionText: "En quoi les démarches d’observation et de création  mises en œuvre par nombre de designers et artistes constituent-elles une forme d’enquête singulière et fertile ? Comment les manières de faire en arts appli-qués contribuent via leurs productions à une réflexion sur le renouvel- lement de l’enquête de terrain, de ses pratiques et de ses objectifs ? À une époque où les défis environnementaux et sociétaux nécessitent de nouveaux modes d’action, et en lien avec les multiples appels à repenser nos démarches d’observation du monde, cet opus défendra l’idée du designer en figure originale de l’enquêteur.",
                year: "2020",
                mockup3D: {
                    img: {
                        coverImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg",
                        backImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732420/HEAD_PUBLISHING/book-back.jpg",
                        sideImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        topImage: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-side.jpg",
                        spineImg: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732421/HEAD_PUBLISHING/book-spine.jpg",
                    },
                    dimensions: {
                        height: "540",
                        width: "400",
                        depth: "58"
                    },
                    animation: {
                        direction: "left",
                        speed: "10s"
                    }
                },
                thumbnailCell: {
                    coverTemplateData: {
                        img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610418123/HEAD_PUBLISHING/IMG_2085.jpg",
                        background: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1607732427/HEAD_PUBLISHING/book-cover.jpg"
                    },
                    textTemplateData: {
                        fieldOne: "",
                        fieldTwo: "",
                        fieldThree: ""
                    }
                },
                previewImages: [
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1579135274/franklin_ford/2_1882_Ford---Tontine-What-It-Is-How-It-Works-1.jpg",
                ],
                externalLinks: [
                    {
                        text: "Livre imprimé",
                        link: "url here"
                    },
                    {
                        text: "Impression à la demande",
                        link: "url here"
                    },
                    {
                        text: "Lecture à l’ecran",
                        link: "url here"
                    },
                    {
                        text: "EPUB",
                        link: "url here"
                    },
                    {
                        text: "PDF",
                        link: "url here"
                    },
                ]
            },
        ]
    }
};
