import React, {Component} from 'react';
import CatalogueCells from "./CatalogueCells.js"
import './App.css';


class CatalogRows extends Component {

  constructor(props) {
     super(props);
     this.state = {
      widthToTranslate: 0,
      screenX: null,
      screenY: null,
     }
   };


  renderSelectedSectionFirstCell = (data, indexOfTopLevelSection) => {
    let sectionData = data.sectionData[0];


    // e.g: years;
    if(indexOfTopLevelSection === 0){
      return (
        <div
            className="catalogue_first_cell filtered_year_first_cell">
          <h1>{data.key}</h1>
        </div>
      )
    // e.g: collections;
    }else if(indexOfTopLevelSection === 1){
      if (!sectionData) {
        console.error('category not matched', data)
        return
      }

      return (
        <div
            id="first_cell"
            className="catalogue_first_cell filtered_collection_first_cell">
          <h1>
            {sectionData.name}
          </h1>
          <div>
            <p>
              {sectionData.description}
            </p>
          </div>
        </div>
      )
    // e.g: co-editions;
    }else if(indexOfTopLevelSection === 2){
      if (!sectionData) {
        console.error('category not matched', data)
        return
      }

      return (
        <div className="catalogue_first_cell filtered_collection_first_cell">
          <h1>
            {sectionData.name}
          </h1>
          <div>
            <p>
              {sectionData.description}
            </p>
          </div>
        </div>
      )
    }
  }


  renderSelectedSectionCell = (data, indexOfTopLevelSection) => {

    let sectionData = data.sectionData[0];

    const books = [...data.entries].sort(({idx: idxA = 0}, {idx: idxB = 0}) => idxB - idxA)

    let cellComponents = books.map((ele, index) => {
      return (
        <CatalogueCells
          cellIndex={index}
          data={ele}
          indexOfTopLevelSection={indexOfTopLevelSection}
          topLevelSectionKey={data.key}
          sectionData={sectionData}
          strapiBookSectionsData={this.props.strapiBookSectionsData}
        />
      )
    })
    return cellComponents;
  };


  renderNavigationArrow = (targetedDivId, direction = 1) => {
    const { widthToTranslate } = this.state

    let translateYArrowPosition = 36;

    let arrowStyling = {
      height: "80px",
      position: "absolute",
      marginInlineStart: "20px",
      top: "0",
      transform: `translateY(-${translateYArrowPosition}vh)`
    }

    if (direction === 1) {
      arrowStyling = {
        ...arrowStyling,
        right: "0",
        paddingLeft: "80px",
      }
    } else {
      if (!widthToTranslate) { // initial position
        arrowStyling = {
          display: 'none'
        }
      }
    }

    const img = direction === 1 ?
      <img style={arrowStyling}
           src="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610445353/HEAD_PUBLISHING/arrow-right.svg"/> :
      <img style={arrowStyling}
           src="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1610445353/HEAD_PUBLISHING/arrow-left.svg"/>


    return (
      <div
        id={`${targetedDivId}_arrow`}
        onClick={() => {
          this.renderTransition(targetedDivId, direction)
        }}
        className="catalogue_row_arrow">
        {img}
      </div>
    )
  }


  renderTransition = (targetedDivId, direction = 1) => {

    let targetedDiv = document.getElementById(targetedDivId);
    let targetedDivWidth = targetedDiv.getBoundingClientRect().width;
    let divToCompare = document.getElementsByClassName('catalogue_first_cell')[0];

    let cellsBorderWidth = 1;
    let cellsPadding = 9;

    // For Juan: fix here;
    // 0.40 is the size of these blocks in vh;
    let widthCells = window.innerHeight * 0.40 + cellsBorderWidth + cellsPadding;
    let widthToTranslate = (widthCells + 8) * direction;

    let valueToReset = targetedDivWidth;

    this.setState({
      widthToTranslate: this.state.widthToTranslate + widthToTranslate
    }, () => {

      if(this.state.widthToTranslate >= targetedDivWidth - 3 * widthToTranslate){
        this.setState({
          widthToTranslate: 0
        }, () => {
          targetedDiv.style.transform = `translate(0, 0)`;
          targetedDiv.style.transitionDuration = "0.2s";
        })
      }else{
        targetedDiv.style.transform = `translate(-${this.state.widthToTranslate}px, 0)`;
        targetedDiv.style.transitionDuration = "0.2s";
      }
    })
  }

  render(){

    let data = this.props.data;
    let indexOfTopLevelSection = this.props.indexOfTopLevelSection;

    // weird fix;
    if(!data.key
      || data.key === ""){
      return null;
    }

    let blancStyling = {
      position: "relative",

    }
    let blancStylingCell = {
      background : "white",
      border: "none"

    }
    return (
      <div className="catalogue_row_wrapper">
        <div
          id={data.key}
          className="catalogue_row_container">
          {this.renderSelectedSectionFirstCell(data, indexOfTopLevelSection)}
          {this.renderSelectedSectionCell(data, indexOfTopLevelSection)}
          <a
            style={blancStyling}>
            <div>
              <div
                className="catalogue_cell_container blank_cell"
                style={blancStylingCell}
              >
              </div>
            </div>
          </a>
        </div>
        {
          data.entries.length >= 4 && this.renderNavigationArrow(data.key, -1)
        }
        {
          data.entries.length >= 4 && this.renderNavigationArrow(data.key, 1)
        }
      </div>
    )
  }
};


export default CatalogRows;
