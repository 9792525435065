import React, {Component} from 'react';
import Header from "./Header.js";
import CatalogueRows from "./CatalogueRows.js";
import './App.css';


class CatalogueContainer extends Component {


  constructor(props) {
     super(props);
     this.state = {
       menuText: [
         {
           text: "Par année",
           key: "year",
           active: true,
           data: []
         },
         {
           text: "Par collection",
           key: "name_collection",
           active: false,
           data: []
         },
         {
           text: "Par coéditions",
           key: "coeditions",
           active: false,
           data: []
         }
       ],
       sortedContent: null,
       sortedContentToDiv: null,
       selectedSectionIndex: 0,
       strapiDataBooks: [],
       strapiBookSectionsData: null,
       isAll: true
     }
   }


   async componentDidMount() {

     let selectedSectionIndex = this.state.selectedSectionIndex;

     let strapiDataBooks = await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/books?_limit=-1`, {

       headers: {
         "Content-Type": "application/json"
       }
     })
       .then(res => res.json())
       .then(
         (result) => {
           return result
         },
         (error) => {
           this.setState({
             isLoaded: true,
             error
           });
         });

     this.setState({
       isloaded: true,
       strapiDataBooks
     })


     let strapiBookSectionsData = await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/book-sections-data?_limit=-1`, {
         headers: {
           "Content-Type": "application/json"
         }
       })
       .then(res => res.json())
       .then(
         (result) => {
           return result
         },
         (error) => {
           this.setState({
             isLoaded: true,
           error
         });
         });

     this.setState({
       isloaded: true,
       strapiBookSectionsData: strapiBookSectionsData.bookSections2,
       strapiBookSectionsCategories: strapiBookSectionsData.categories,
     })

     let entries = this.state.strapiDataBooks;

     const sortedContent = this.state.menuText
       .map(el => this.filterSelectedContent(entries, el.key, this.state.strapiBookSectionsData))



     let sortedContentToDiv = sortedContent.map((el, index) => {
       let indexOfTopLevelSection = index;
       return el.map((ele, index) => {
         return (
           <div>
             <CatalogueRows
               key={index}
               indexOfTopLevelSection={indexOfTopLevelSection}
               data={ele}
               selectedSectionIndex={selectedSectionIndex}
               strapiBookSectionsData={this.state.strapiBookSectionsData}
             />
           </div>
         )
       })
     });

     this.setState({
       sortedContent,
       sortedContentToDiv

     })
   };


  triggerselectedSectionIndex = (selectedSectionIndex) => {

    let catalogeRowContainers = document.getElementsByClassName("catalogue_row_container");
    let htmlListToArray = [...catalogeRowContainers];

    htmlListToArray.map((ele, index) => {
      ele.style.transform = "none"
      ele.style.transition = "0s"
    });

    this.setState({
      selectedSectionIndex,
    }, () => {
      let selectedSectionIndex = this.state.selectedSectionIndex;
      let updatedMenuText = this.state.menuText
        .map((ele, index) => {

          if (index === selectedSectionIndex) {
            return (
              {
                text: ele.text,
                key: ele.key,
                data: ele.data,
                active: true,
              }
            )
          } else {
            return (
              {
                text: ele.text,
                key: ele.key,
                data: ele.data,
                active: false
              }
            )
          }
        });
      this.setState({
        menuText: updatedMenuText
      })
    })

  };


  filterSelectedContent = (array, section, strapiBookSectionsData) => {
    let newData = [];

    for (const item of array) {

      let key = item[section];
      const container = newData.find(e => e.key === key);
      if (container) {
        container.entries.push(item)
      } else {
        let newEntry = {
          key,
          section: section,
          active: false,
          entries: [item],
          sectionData: strapiBookSectionsData.filter(e => e.name === key)
        };
        newData.push(newEntry);
      }
    }

    let sortedNewData = newData.sort((a, b) => b.key - a.key);

    if (newData[0].section === "name_collection") {
      sortedNewData = newData.sort((a, b) => {
          if (!a.sectionData[0] || !b.sectionData[0]) {
            return 0
          }
          return a.sectionData[0].order - b.sectionData[0].order
        }
      );
    }

    this.initStateArray(sortedNewData, section, this.state.menuText);
    return sortedNewData;
  };


  initStateArray = (newData, section, menuText) => {

    const elementsIndex = menuText.findIndex(
      element => element.key == section
    );

    let newMenuTextArray = [...menuText];
    newMenuTextArray[elementsIndex] = {
      ...newMenuTextArray[elementsIndex],
      data: newData
    };
    this.setState({
      menuText: newMenuTextArray
    })
  }

  renderCatalogueMenuFirstLayer = () => {

    let selectedFirstLayerIndex = this.state.menuText;
    let firstLayerMenu = this.state.menuText
      .map((ele, index) => {
        return (
          <div>
            <h1
              key={index}
              className={this.renderDynamicMenuStyling(ele.active)}
              onClick={() => { this.triggerselectedSectionIndex(index)}}>
              {ele.text}
            </h1>
          </div>
        );
      });

    return (
      <div className="catalogue_menu_first_layer_container">
        {firstLayerMenu}
      </div>
    )
  };

  renderDynamicMenuStyling = (active) => {
    if (active) {
      return "catalogue_menu_selected"
    }else{
      return null;
    }
  }

  renderCatalogueMenuSecondLayer = () => {

    let selectedSectionIndex = this.state.selectedSectionIndex;
    let sortedContent = this.state.sortedContent;

    if (!sortedContent) {
      return null;
    }

    let selectedSecondLayerMenu = sortedContent[selectedSectionIndex];
    let selectedSecondLayerMenuToDiv = selectedSecondLayerMenu
      .map((ele, index) => {

        let keyCapitalized = ele.key?.charAt(0)
          .toUpperCase() + ele.key?.slice(1);

        let menuSpanId = `span_${ele.key}`


        return (
          <>{!keyCapitalized ? null
          : <span
            id={menuSpanId}
            className={this.renderDynamicMenuStyling(ele.active)}
            onClick={() => { this.toggleSelectedSectionRow(ele, menuSpanId) }}>
            {keyCapitalized}
          </span>}
          </>
        )
      })
    return (
      <div className="catalogue_menu_second_layer_container">
        <span
          id="allCtaId"
          className={this.renderDynamicMenuStyling(this.state.isAll)}
          onClick={() => { this.toggleAllSectionsRow() }}>
          Tout
        </span>
        {selectedSecondLayerMenuToDiv}
      </div>
    )
  };


  toggleSelectedSectionRow = (ele, menuSpanId) => {

    let menuText = this.state.menuText;
    let section = ele.section;
    let selectedDivKey = ele.key;

    if(!menuText){
      return null;
    }

    let newMenuTextArray = [...menuText];
    const elementsIndex = menuText.findIndex(
      element => element.key == section
    );

    let newMenuTextSubArray = newMenuTextArray[elementsIndex].data;
    newMenuTextSubArray.map((ele, index) => {
      if(ele.key === selectedDivKey){
        if(ele.active){
          ele.active = false
        }else{
          ele.active = true
        }
      }
    })

    this.setState({
      menuText: newMenuTextArray,
      isAll: false
    }, () => {
      let menuText = this.state.menuText;
      let selectedSectionIndex = this.state.selectedSectionIndex;
      let selectedComponentsInViewData = menuText[selectedSectionIndex].data;


      let selectedComponentsInView = selectedComponentsInViewData
      .filter((ele) => {
        return ele.active;
      })

      if(selectedComponentsInView.length === 0){
        return this.toggleAllSectionsRow();
      }

      selectedComponentsInViewData.map((ele, index) => {

        let arraw = `${ele.key}_arrow`;
        let arrowToToggle = Array.from(document.querySelectorAll('.catalogue_row_arrow')).filter(e=> e.id ===arraw);
        let divToToggle = document.getElementById(ele.key);

        if(!divToToggle){
          return null;
        }

        if(ele.active){
          divToToggle.style.display = "inline-flex";
          arrowToToggle && (arrowToToggle.forEach(e=> e.style.display = "block"));

        }else{
          divToToggle.style.display = "none";
          arrowToToggle && (arrowToToggle.forEach(e=> e.style.display = "none"));
        }
      })
    })
    this.triggerInView(ele);
  };


  triggerInView = (ele) => {
    if(ele.active){
      setTimeout(() => {
          let eleDiv = document.getElementById(ele.key);
          window.scroll({top: eleDiv.offsetTop, behavior: 'smooth'});
        },
      200);
    }
  }





  toggleAllSectionsRow = () => {

    let menuText = this.state.menuText;
    let selectedSectionIndex = this.state.selectedSectionIndex;
    let newMenuTextArray = [...menuText];

    let selectedComponentsInViewData = menuText[selectedSectionIndex].data;

    selectedComponentsInViewData.map((ele, index) => {
      ele.active = false
    })
    this.setState({
        menuText: newMenuTextArray,
        isAll: true
    }, () => {

      selectedComponentsInViewData.map((ele, index) => {

        let arraw = `${ele.key}_arrow`;
        let arrowToToggle = document.getElementById(arraw);
        let divToToggle = document.getElementById(ele.key);

        if(!divToToggle){
          return null;
        }

        divToToggle.style.display = "inline-flex";
        arrowToToggle && (arrowToToggle.style.display = "block");
      })
    })
  };


  renderCatalogueMenu = () => {
    return (
      <nav className="catalogue_menu_container">
        {this.renderCatalogueMenuFirstLayer()}
        {this.renderCatalogueMenuSecondLayer()}
      </nav>
    )
  }

  renderCatalogueBody = () => {

    let state = this.state;
    if (!state.sortedContentToDiv) {
      return null;
    }

    return (
      <div>
        {state.sortedContentToDiv[state.selectedSectionIndex]}
      </div>
    )
  };


  renderHeaderOnRoutePath = () => {
    let path = this.props.match;

    if (path === undefined) {
      return null
    } else {
      window.scrollTo(0, 0)
      return (
        <Header
          {...this.state}
        />
      )
    }
  };


  updateStylingOnPath = () => {
    let path = this.props.match;
    if (path === undefined) {
      return null;
    } else {
      return { paddingTop: "20vh" }
    }
  }


  render() {
    if (!this.state.strapiDataBooks) {
      return (
        <div>
          loading
        </div>
      )
    }

    return (
      <div
        className="catalogue_row_main_container"
        style={this.updateStylingOnPath()}>
            {this.renderHeaderOnRoutePath()}
            {this.renderCatalogueMenu()}
            {this.renderCatalogueBody()}
      </div>
    )
  }
}




export default CatalogueContainer;
